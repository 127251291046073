import React, { Component } from 'react'
import { Col, Form, FormGroup, Label, Input, InputGroup, InputGroupText } from 'reactstrap'
import StarRatingComponent from 'react-star-rating-component';
import Rating from './Rating'
class Answer extends Component {
    constructor(props) {
        super(props);
        this.state = { value: null }
        this.state.type = this.props.answer.type
        this.state.answer = this.props.answer
    }
    
    ratingAnswerType = () => {

        return (
            <div className="text-center">
                <Rating callback={this.handleAnswer.bind(this)}></Rating>
            </div>
        )
 
    };

    freeTextAnswerType = () => {
        return (
            <InputGroup>
                <InputGroupText className="w-100">
                    <textarea value={this.state.answer.value && this.state.answer.value.toString()}
                        onChange={this.handleAnswer.bind(this)} className="w-100" rows="8"  >
                    </textarea>
                </InputGroupText>

            </InputGroup>)
    };

    bitAnswerType = () => {
        return (
            <Form className="mdns_radio">
                <FormGroup>
                    <Label>
                        <Input type="radio" name="bitRadio" value="true" onChange={this.handleAnswer.bind(this)} checked={this.state.answer.value && this.state.answer.value == "true"} />{' '}
                        כן
                </Label>
                </FormGroup>
                <FormGroup>
                    <Label>
                        <Input type="radio" name="bitRadio" value="false" onChange={this.handleAnswer.bind(this)} checked={this.state.answer.value && this.state.answer.value == "false"} />{' '}
                        לא
                </Label>
                </FormGroup>
            </Form>
        )
    }

    radioAnswerType = () => {
        let options = this.state.answer.options;
        let optionItems = options.map((option) =>
            <FormGroup>
                <Label>
                    <Input type="radio" name="radio1" value={option.label} onChange={this.handleAnswer.bind(this)} checked={this.state.answer.value && this.state.answer.value == option.label} />{option.label}
                </Label>
            </FormGroup>

        );

        return (
            < Form className="mdns_radio">
                {optionItems}
            </Form >
        )
    }


    render() {
        const { type } = this.state;

        if (type == "rate") {
            return this.ratingAnswerType();
        }
        else if (type == "bit") {
            return this.bitAnswerType();
        }
        else if (type == "radio") {
            return this.radioAnswerType();
        }
        else {
            return this.freeTextAnswerType();
        }
    }

    handleAnswer(value, prevValue, name) {
        const { type } = this.state;
        if (type == "rate") {
            this.setState({ value: value });
            this.props.handleAnswer(value);
        }
        else if (type == "bit") {
            this.setState({ value: value.target.value });
            this.props.handleAnswer(value.target.value);

        }
        else if (type == "radio") {
            this.setState({ value: value.target.value });
            this.props.handleAnswer(value.target.value);
        }
        else {
            this.setState({ textValue: value.target.value });
            this.props.handleAnswer(value.target.value);
        }

    }
}

export default Answer
