import React, { Component } from 'react';
import { Row, Col, ListGroup, Button, ListGroupItem, Card, CardImg, CardHeader, CardBody, Jumbotron, Container } from 'reactstrap'

class Rating extends Component {

    constructor(props) {
        super(props)
        this.state = {}
        this.state.selectedRating = -1
        this.state.defaultFontSize = 1.6 + "em";
        this.state.increasedFontSize = 2.1 + "em";
        this.ratingChanged = this.ratingChanged.bind(this);
        this.ratingChangedCallback = this.props.callback;
        this.state.items = [
            { value: 5, icon: "😃" },
            { value: 4, icon: "🙂" },
            { value: 3, icon: "😐" },
            { value: 2, icon: "😕" },
            { value: 1, icon: "😞" }
        ]
    }

    ratingChangedCallback;

 
    render() {
        const { defaultFontSize, items } = this.state;
        return (
            <ul className="list-inline">{
                items.map(item =>
                    <li key={item.value} style={{ cursor: "pointer" }} className="list-inline-item">
                        <span style={{ fontSize: item.value == this.state.selectedRating ? this.state.increasedFontSize : this.state.defaultFontSize }} onClick={(e) => this.ratingChanged(item.value, e)}>{item.icon}</span>
                    </li>
                )
            }
                <li></li>
            </ul >
        )
    }


    ratingChanged(rating, event) {
        this.setState({ selectedRating: rating });
        this.ratingChangedCallback(rating);

    }
}
export default Rating