import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader, CardTitle, ListGroupItem, CardText } from 'reactstrap';
import Answer from './Answer';
class Question extends Component {
    constructor(props) {
        super(props);
        this.state = {
            question: props.question,
        }

        this.handleAnswer = this.handleAnswer.bind(this);
    }

    componentDidMount() {

    }

    handleAnswer(answerValue) {
        const { question } = this.state;

        question.answer.value = answerValue;
        this.setState({ question: question });
        // this.props.validate();
    }

    render = () => {
        return (

            <Card className="shadow mt-3 border-0 row">
                <CardHeader  tag="h6" className="bg-secondary text-light">
                {this.state.question.title}
                
                </CardHeader>
                <CardHeader><CardText>
                <small>{this.state.question.description}</small></CardText></CardHeader>
                <CardBody>
                    <div >
                        <Answer key={this.state.question.qid} handleAnswer={this.handleAnswer} answer={this.state.question.answer} />
                    </div>
                </CardBody>
            </Card >
        )
    }
}

export default Question