import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import Home from './Home';
import CustomerSurvey from './CustomerSurvey';
import { Container } from 'reactstrap';
import Missing from './Missing';
import { Helmet } from 'react-helmet';
class App extends Component {

  componentDidMount() {
    // document.body.dir = "rtl";
    // document.title = 'סקר לקוחות';
  }
  render() {
    return (
      <Container className="rtl col-md-6 p-4 justify-content-center">
        <Helmet>
          <title>סקר ללקוח</title>
        </Helmet>
        <Switch>
          <Route exact path="/home" component={Home} />
          <Route exact path="/survey/sid/:id" component={CustomerSurvey} />
          <Route component={Missing} />
        </Switch>
      </Container>
    );
  }
}

export default App;
