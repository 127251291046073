import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import Question from './Question'
import { Row, Col, ListGroup, Button, ListGroupItem, Card, CardImg, CardHeader, CardBody, Jumbotron, Container } from 'reactstrap'
import SurveyService from './services/Survey-Service';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import Inactive from './Inactive';

 

class CustomerSurvey extends Component {
    constructor(props) {
        super(props);
        this.state = { isVisible: false, id: props.match.params.id, survey: null, valid: false, submitted: false, redirect: false, sortedQuestions: null };
        this.submit = this.submit.bind(this);
    }


    async componentDidMount() {
        await this.getSurveryQuestions();
        this.setState({ isVisible: true })
    }

    shouldRenderQuestions() {
        return this.state && this.state.survey &&
            (this.state.survey.closed == null || this.state.survey.closed == false)
    }

    isSubmitDisabled() {
        return this.state.valid == false && this.state.submitted == false;
    }

    isButtonHidden() {
        return this.state.submitted == true || this.state.survey == null;
    }

    render() {
        return (
            <div className="mt-5">
                {
                    this.state.isVisible == false &&
                    <div className="center-block">
                        נא להמתין...
                   </div>
                }
                {
                    this.state.survey &&
                    <Container>
                        <Row style={{ justifyContent: 'center' }}><img src={this.state.survey.logoUrl} alt="Logo" width="450px" height="200px" /></Row>
                    </Container>
                }
                {
                    this.state.redirect &&
                    //<Redirect to='/Inactive.js' />
                    <Route component={Inactive} />
                }
                {
                    this.state.submitted &&
                    this.state.survey &&
                    <Jumbotron fluid={true}>
                        <Container>
                            <h1 className="display-4">תודה!</h1>
                            <p className="lead">{this.state.survey.thankyouPhrase}</p>
                        </Container>
                    </Jumbotron>
                }
                {
                    this.state.submitted == false &&
                    this.state.survey &&
                    <Container>
                        <br /><br />
                        <Row><h6>{this.state.survey.welcomePhrase}</h6></Row>
                    </Container>
                }
                {
                    this.shouldRenderQuestions() &&
                    this.state.survey.questions.sort((a, b) => (a.display > b.display) ? 1 : ((b.display > a.display) ? -1 : 0)).map((q, i) =>
                        // <Slide key={q.qid} >
                        <Question key={q.qid} question={q} />
                        // </Slide>
                    )
                }
                {
                    this.shouldRenderQuestions() &&
                    <Card className="mt-3 border-0 text-center">
                        <CardBody>
                            <Button
                                // disabled={this.isSubmitDisabled()}
                                hidden={this.isButtonHidden()}
                                onClick={this.submit} className="btn-success">סיום</Button>
                        </CardBody>
                    </Card>
                }
            </div >
        );
    }

    submit() {
        SurveyService.postSurvey(this.state.survey).then(res => {
            this.setState({ survey: res, submitted: true });
        });
    }

    async getSurveryQuestions() {
        let res = await SurveyService.getSurvey(this.state.id);
        if (null == res) {
            this.setState({ redirect: true });
        }
        this.setState({ survey: res })
    }
}

export default CustomerSurvey