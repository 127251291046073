import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import logo from './logo.svg';
import './App.css';

class Home extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>Home</div>
        );
    }
}

export default Home;
