const ES6Promise = require("es6-promise");
ES6Promise.polyfill();

const axios = require('axios')
    .default
    .create({ baseURL: process.env.REACT_APP_BASE_URL });

class SurveyService {
    static getSurvey(sid) {
        return axios.get(`/survey/sid/${sid}`).then(res => res.data);
    }

    static postSurvey(survey) {
        return axios.post(`/survey`, survey).then(res => res.data);
    }
}

export default SurveyService