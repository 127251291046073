import React, { Component } from 'react';
import { Alert } from 'reactstrap';
class Inactive extends Component {
    constructor(props) {
        super(props);
    }

    render = () => {
        return (<Alert style={{ marginTop: 40 + "%" }} color="danger">לא ניתן לענות שוב על סקר זה</Alert>)
    }
}

export default Inactive